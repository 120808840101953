<template>
  <section class="build-to-rent-furnished">
    <section class="hero">
      <div class="hero-container">
        <h1>Construire pour louer en meublé : pourquoi c’est rentable ?</h1>
      </div>
    </section>
    <div class="hero-img">
      <img
        src="@/assets/img/pages/build-to-rent-furnished-1.jpg"
        alt="Construire pour louer en meublé : pourquoi c’est rentable ?"
      />
    </div>
    <article class="grid-container article">
      <div class="article-title">
        <h3>
          Outre les recettes locatives, plus élevées que celles d’un logement nu, ce type
          d’investissement permet d’alléger le montant de l’impôt sur le revenu grâce aux
          abattements fiscaux accordés dans le cadre du statut LMNP (Loueur en meublé non
          professionnel). Explication.
        </h3>
      </div>
      <div class="article-card">
        <p>
          Quelle catégorie de biens trouve le plus rapidement preneur sur le marché de la location ?
          Entre le départ d’un occupant et la signature d’un nouveau bail, il s’écoule 31 jours en
          moyenne pour une maison de deux pièces tout équipée, soit deux semaines de moins que pour
          l’ensemble des meublés, appartements et pavillons confondus (46 jours). Mieux : ce délai
          est presque deux fois plus court que pour un habitat proposé « nu » (69 jours, selon le
          baromètre le plus récent de seloger.com).
        </p>
        <p>
          <router-link :to="{ name: 'project-house-construction' }">Construire sa maison</router-link> pour la louer, c’est donc se prémunir contre un risque de « carence »
          prolongée, ces périodes de plusieurs mois au cours desquels le logement, faute de
          candidats, n’est plus occupé et coûte davantage à son propriétaire qu’il ne lui rapporte.
          De cette forte demande découle un autre avantage : un niveau de loyer plus élevé que celui
          d’un appartement (+ 23% en moyenne d’après des données d’avril 2020). Autre facteur à
          prendre en ligne de compte : un bailleur qui met une maison meublée sur le marché en
          tirera un revenu mensuel de 872 euros (il s’agit toujours d’une moyenne nationale, toutes
          surface et zones géographiques confondues), soit 42 euros de plus que le même bien loué
          vide.
        </p>
        <p>
          Ces gains de court terme se combinent à d’autres paramètres qui assurent la rentabilité de
          l’<router-link :to="{ name: 'project-house-rental-invest' }">investissement locatif</router-link> sur des durées plus longues grâce aux facilités fiscales offertes
          par le statut LMNP (Loueur en meublé non professionnel). En quoi consiste ce régime ?
        </p>
      </div>
      <div class="article-tax-allowance">
        <h2>Un abattement de 50% sur les revenus locatifs à déclarer</h2>
        <div class="article-tax-allowance-img">
          <img
            src="@/assets/img/pages/build-to-rent-furnished-2.jpg"
            alt="Un abattement de 50% sur les revenus locatifs à déclarer"
          />
        </div>
        <div class="article-tax-allowance-text">
          <p>
            Avant toute chose, rappelons qu’un meublé doit, pour être considéré comme tel sur le
            plan juridique, mettre à disposition de son occupant des équipements préinstallés dont
            la liste est fixée par la loi (décret N°3025-981). Si ce prérequis est rempli, la
            qualification de l’activité en LMNP est possible, à condition toutefois que le montant
            des recettes locatives perçues sur un même bien par le bailleur ne dépasse pas 23 000
            euros par an, et n’excède pas non plus la moitié des revenus totalisés par l’ensemble
            des membres de son foyer fiscal (pour faire clair, la location d’un logement meublé ne
            doit pas constituer la majorité des ressources déclarées par le contribuable).
          </p>
          <p>
            L’objectif des investisseurs éligibles à ce statut particulier vise à défiscaliser les
            loyers encaissés : l’opération donne lieu à un abattement de 50% s’ils optent pour le
            régime simplifié micro-bic* (ils peuvent y prétendre si l’ensemble des recettes qu’ils
            tirent de l’ensemble de leurs biens immobiliers loués à des particuliers est inférieur à
            72 500 euros, soit le plafond applicable en 2020 aux auto-entrepreneurs qui exercent une
            prestation de services). En bref, dans ce scénario, seule la moitié des revenus locatifs
            est retenue dans le calcul de l’impôt (à titre de comparaison, la location d’un bien-nu
            qui, jusqu’à 15 000 euros, relève du régime micro-foncier, donne droit à un abattement
            moins avantageux, soit 30%).
          </p>
          <p>
            Précision : dans le système allégé micro-bic, qui intéresse donc les LMNP, il n’est pas
            obligatoire de tenir une comptabilité. En revanche, à ce stade, certaines charges (frais
            d’entretien et de réparation, intérêts d’emprunt, amortissement du mobilier et des
            locaux etc…) ne sont pas déductibles : seul le régime réel les prend en compte.
          </p>
          <p>*bénéfices industriels et commerciaux</p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'build-to-rent-furnished',
};
</script>

<style lang="sass">
.build-to-rent-furnished
  background-color: $medium-bg
  padding-bottom: 6rem

  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center

    &-container
      margin-top: 8rem
      width: 60rem

      h1
        color: $white
        text-align: center

    &-img
      display: flex
      flex-direction: row
      justify-content: center

      img
        border-radius: $global-border-radius
        margin-top: -15rem

  .article
    width: 90%
    max-width: 950px

    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto

      h3
        text-align: center

    &-card
      background-color: $white
      border-radius: $global-border-radius
      box-shadow: $drop-shadow
      padding: 2rem 2.5rem
      margin: 4.5rem auto 0 auto

      p + p
        margin-top: 1rem

      p
        a
          color: $primary
          font-size: 16px
          font-weight: bold
          text-decoration: none

    &-tax-allowance
      margin: 4.5rem auto 0 auto

      h2
        text-align: center
        color: $primary

      &-img
        margin-top: 3rem

        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius

      &-text
        margin-top: 3rem

        p + p
          margin-top: 1rem
  @media (max-width: 768px)
    .hero
      height: 25rem
      &-container
        width: 90%

      &-img
        img
          object-fit: cover
          object-position: right
          width: 90%
          height: 250px
          margin-top: -8rem
    .article
      &-title
        margin-top: 2rem
      &-card
        margin-top: 2rem
        padding: 1.75rem 1rem
    .article
      &-tax-allowance
        margin-top: 3.5rem
        &-img
          margin-top: 2rem

          img
            height: 220px

        &-text
          margin-top: 2rem
</style>
